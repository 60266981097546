.container {
    position: relative;
}

.nad {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 95vw;
    height: 50px;
    z-index: 10;
}

.filter {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.search {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.inp {
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.filterComponent {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #2e7d32;
    padding: 10px 0;
    position: fixed;
    top: 50px; /* Ajusta según la altura de tu navbar */
    left: 0;
    width: 80px; /* Ajusta el ancho del menú desplegable */
    z-index: 9;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    height: 100%; /* Para que ocupe todo el alto de la pantalla */
}

.navItems.show {
    display: flex;
}

.navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    text-align: center;
    color: white;
}

.navItem img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
}

.banner{
    height: 22vh;
    margin-top: 50px;
    background-color: rgba(255, 255, 255, 0.219);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img {
    width: 40%;
}

.imgTwo {
    margin-top: 30px;
    width: 20%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
.textBanner{
    width: 90%;
    color: white;
    text-align: center;
}
.navItem {
    filter: grayscale(100%); /* Aplica un filtro gris a los iconos inactivos */
  }
  
  .navItem.active {
    filter: none; /* Remueve el filtro gris para los iconos activos */
  }

  .searchInput{
 width: 140px;
  }

  @media (max-width: 410px) {
    .searchInput{
        width: 100px;
         }
  }
  @media (max-height: 740px) {
    .banner{
        height: 30vh;
         }
  }
  @media (max-height: 600px) {
    .banner{
        height: 35vh;
         }
  }